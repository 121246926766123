import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// the translations
// (tip move them in a JSON file and import them)
const resources = {
    en: {
        translation: {
            "meta":{
                "title":"友和YOHO SuperPass  Member Channel"
            },
            "intro":{
                "title":"%s will be launched very soon! As being a YOHO member, you can register for the YOHO SuperPass and get %s for leading to the technology peak!",
                "subtitle_1":"YOHO Member Exclusive",
                "subtitle_1_des":"%s is reserved for YOHO members only. Register as a YOHO member now!",
                "subtitle_2":"VIP Superior Passage",
                "subtitle_2_des":"YOHO VIP members can enter the priority list after registration.",
                "subtitle_3":"1-minute Only",
                "subtitle_3_des":"Register by entering simple information to verify member identity",
                "register_btn":"Register Now",
                "non_member":"Not a member yet?",
                "non_member_btn":"Free registration",
                "comming_soon":"Comming soon!!"
            }, 
            "form":{
                "title":"Register",
                "subtitle":"Please enter your mobile number and email registered in YOHO to receive further details of YOHO SuperPass.",
                "mobile":"Mobile number",
                "email":"Email",
                "check_data_reminder":"Please confirm the mobile number and email address you provide are valid before submission.",
                "accept_terms":"I have read and accepted the above Terms and Conditions and the privacy policy."
            },            
            "error":{
                "title":"Error",
                "fields_empty":"Please fill in all the information needed for the registration.",
                "un_vaild_email":"Sorry your email address is not valid, please input the email address again!",
                "accept_tnC":"Please accept the Terms and Conditions before submit.",
                "no_account":"This member account is not existed.",
                "registered":"Your member account has been registered!",
                "event_expired":"Event is expired"
            },
            "terms":{
                "title":"YOHO %s Campaign Terms and Conditions",
                "term_1":"1. The campaign is exclusive for YOHO members.",
                "term_2":"2. YOHO SuperPass campaign period is from 19 Oct 2020 12:00p.m. to 25 Oct 2020 11:59p.m..",
                "term_3":"3. Successfully getting YOHO SuperPass does not necessarily represent the purchasing status.",
                "term_4":"4. The purchasing result and the exact redemption date depend on the product supply.",
                "term_5":"5. The exact purchase date and time will be notified by email to the successfully registered members at a later time.",
                "term_6":"6. YOHO reserves the right to discontinue YOHO SuperPass campaign or alter the terms and conditions without prior notice. In case of disputes, YOHO reserves the rights of final decision.",
            },
            "done":{
                "title":"Your YOHO SuperPass notification email has been sent to your email address.",
                "btn_text":"come to visit our YOHO Shop",
                "email":"If you haven't receive the notification email, please check your spam or junk folder.Please add %e to your email address book to ensure our latest updates and offers can be received by you. If you still cannot receive our email, please call 5335 6996 for help."
            },
            "no_event":{
                "title":"Oh! The best is yet to come...",
                "subtitle":"Just stay tuned!",
                "btn_text":"or come to visit our YOHO Shop"
            }
        }
    },
    tc: {
        translation: {
            "meta":{
                "title":"友和YOHO SuperPass  會員特快通行証"
            },
            "intro":{
                "title":"%s即將推出！只要係友和YOHO會員，再登記友和YOHO SuperPass，%s極速到手，率先感受科技高峰！",
                "subtitle_1":"友和YOHO會員限定",
                "subtitle_1_des":"%s當然留俾我哋友和YOHO會員先，即刻登記做會員啦！",
                "subtitle_2":"VIP特快通道",
                "subtitle_2_des":"友和YOHO VIP會員大有著數，登記即入特快通道優先輪候！",
                "subtitle_3":"1分鐘簡單登記",
                "subtitle_3_des":"填寫簡單資料即可認證會員身份快速登記",
                "register_btn":"立即登記",
                "non_member":"未成為會員?",
                "non_member_btn":"免費註冊",
                "comming_soon":"密切留意!!"
            }, 
            "form":{
                "title":"註冊",
                "subtitle":"請輸入你於友和YOHO網站登記的流動電話號碼及電郵，友和YOHO SuperPass更多活動資訊將會發送到會員登記之電郵地址。",
                "mobile":"流動電話",
                "email":"電郵",
                "check_data_reminder":"提交資料前請確保你所輸入的流動電話號碼及電郵正確無誤。",
                "accept_terms":"我已閱讀並接受以上條款及細則和私隱政策。"
            },
            "error":{
                "title":"錯誤",
                "fields_empty":"請輸入所需資料",
                "un_vaild_email":"抱歉，您的電子郵件地址無效，請再次輸入電子郵件地址！",
                "accept_tnC":"提交前，請接受活動條款及細則",
                "no_account":"會員賬戶資料不正確",
                "registered":"您的會員帳號已經註冊！",
                "event_expired":"活動已過期"
            },
            "terms":{
                "title":"友和YOHO %s 活動條款及細則",
                "term_1":"1. 是次活動只限友和會員參加",
                "term_2":"2. 會員必須於2020年10月18日23時59分前升級為友和YOHO VIP會員，方可於活動進行時進入VIP特快通道。我們將不時於友和網站及Facebook專頁公佈活動詳情。",
                "term_3":"3. 成功登記不代表成功購買，最終購買結果及確實取機日期視乎貨品供應而定。",
                "term_4":"4. 成功登記後，閣下將會收到由系統發出的「通知電郵」確認是次登記。登記申請一經遞交後，不能作任何更改。如輸入資料錯誤導致未能聯絡閣下，該登記將視為無效。",
                "term_5":"5. 確實購買日期及時間將會於稍後時間以電郵通知成功登記之會員。",
                "term_6":"6. 友和YOHO 有權終止友和 SuperPass 推廣活動或更改條款及細則，而不作事前通知。如有任何爭議，友和YOHO 保留最終決定權。",
            },
            "done":{
                "title":"友和YOHO SuperPass通知電郵已發送到你的電郵地址。",
                "btn_text":"到友和YOHO 網購店逛逛",
                "email":"如未能收到確認電郵，請檢查你的垃圾郵件資料匣。為避免我們發送的最新消息/資訊被送到垃圾郵件匣，請將 %e 加到你的電郵地址簿內。若仍未收到我們的電郵，請致電 5335 6996 查詢。"
            },
            "no_event":{
                "title":"噢! 暫時未有活動啊!",
                "subtitle":"請密切留意我哋嘅最新動態",
                "btn_text":"或到 友和YOHO 網購店逛逛"
            }
        }
    },
    sc: {
        translation: {
            "intro":{

            }, 
            "form":{

            },
            "done":{
                
            }
        }
    }

};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "tc",
        nsSeparator: ':',
        keySeparator: '.', // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;