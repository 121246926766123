import React from 'react';
import Typography from '@material-ui/core/Typography';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/Create';
import Check from '@material-ui/icons/Check';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';

function SuperPassStepper(props) {
    const { steps, activeStep } = props;

    function renderLabels() {
        let array = [];
        steps.forEach((step, index) => {
            array.push(
                <Grid item>
                    {activeStep > index ?
                        <Avatar style={{ backgroundColor: '#f5f5f5' }}>
                            <Icon color="primary">{step.icon}</Icon>
                        </Avatar>
                        : <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                            style={{ border: "3px solid", borderRadius: "50%", width:"40px", height:"40px" }}>
                            <Grid item>
                                {step.icon}
                            </Grid>
                        </Grid>
                    }
                </Grid>
            )
            if (steps.length - 1 != index) {
                array.push(
                    <Grid item>
                        <hr style={{ width: 200, margin: "0px 10px" }} />
                    </Grid>
                )
            }
        });
        return array;
    }

    return (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ margin: "10px 0px" }}
        >
            {renderLabels()}
        </Grid>
    );
}

export default SuperPassStepper;
