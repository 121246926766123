import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import SuperPassStepper from './SuperPassStepper';
import i18n from './i18n';
import CreateIcon from '@material-ui/icons/Create';
import Container from '@material-ui/core/Container';
import DoneIcon from '@material-ui/icons/Done';

function AppMenu(props) {
    function renderLanguagebtn() {
        const btn = [
            { btnText: "繁", value: "tc" },
            { btnText: "EN", value: "en" },
            // { btnText: "簡", value: "sc" }
        ]
        var array = [];
        btn.forEach(e => {
            if (i18n.language != e.value)
                array.push(
                    <IconButton
                        color="inherit"
                        size="small"
                        onClick={() => {
                            props.setLanguage(e.value)
                            i18n.changeLanguage(e.value)
                        }}>
                        {e.btnText}
                    </IconButton>
                );
        });

        return array;
    }

    return (
        <AppBar position="static">
            <Container maxWidth="lg">
            <Toolbar>
                <Grid container direction="row" justify="space-between" alignItems="center">
                    <Grid item>
                        <img src={require('../img/iphone12_fastpass_header_02_v3.png')} style={{maxWidth:"50vw"}}/>
                    </Grid>
                    <Grid item>
                        {renderLanguagebtn()}
                    </Grid>
                </Grid>
            </Toolbar>
            {/* {props.userStep!=0?<SuperPassStepper activeStep={props.userStep} steps={[{label:'a', icon:<CreateIcon/>}, {label:'b', icon:<DoneIcon/>}]}/>:""} */}
            </Container>
        </AppBar>
    );
}

export default AppMenu;
