import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { withTranslation } from 'react-i18next';
import {primary_color} from './Theme.js';

function EventRegSuccess(props) {
    const parse = require('html-react-parser');
    const { t, i18n } = props;

    return (
        <React.Fragment>
            <Typography variant="h5" style={{ marginBottom: 50 }}>
               {t('done.title')}
                </Typography>
            <Grid item style={{ width: "100%", border: "2px solid", padding: 8, borderColor: primary_color }}>
                <Grid container direction="column" justify="center" alignItems="center">
                    <Typography variant="h6" gutterBottom color="primary" style={{}}>
                        {props.email}
                    </Typography>
                </Grid>
            </Grid>
            <br />
            <Typography variant="body2" gutterBottom>
               {parse(t('done.email').replace(/%e/g, '<a href="mailto:noreply@email.yohohongkong.com">noreply@email.yohohongkong.com</a>'))}
            </Typography>
            <Grid container direction="column" justify="center" alignItems="center">
                <Grid item>
                    <Button size="large" color="primary" endIcon={<ArrowForwardIcon />} href="https://www.yohohongkong.com">{t('done.btn_text')}</Button>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default withTranslation()(EventRegSuccess);
