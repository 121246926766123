import React, { Component } from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

export const primary_color = "#02acee";

const muitheme = createMuiTheme({
    typography: {
        fontFamily: "'Poppins', sans-serif",
        useNextVariants: true
    },
    palette: {
        type: 'light',
        primary: {
            main:primary_color,
            contrastText: '#fff'
        },
        secondary: {
            main:'#f5f5f5',
            contrastText: '#02acee',
        }
    }
});


export function Theme(props) {
    return (
        <ThemeProvider theme={muitheme}>
            {props.children}
        </ThemeProvider>
    );
}