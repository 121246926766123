import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Grid } from '@material-ui/core';

function SimpleBackdrop(props) {

    return (
        <Backdrop open={props.open} style={{ zIndex: 999999 }}>
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                spacing={4}
            >
                <Grid item>
                    <img src={require('../img/iphone12_fastpass_header_02_v3.png')} style={{ maxWidth: "50vw" }} />
                </Grid>
                <Grid item>
                    <CircularProgress color="secondary" />
                </Grid>
            </Grid>
        </Backdrop>
    );
}

export default SimpleBackdrop;