import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { withTranslation } from 'react-i18next';
import i18n from './i18n';

function Tnc(props) {
    const {campaignData, t, i18n } = props;

    function returnTerms() {

        let array = [];
        let terms_json = JSON.parse(campaignData['tnc_' + i18n.language]);
        for (let i = 0; i < Object.keys(terms_json).length; i++) {
            let target = Object.keys(terms_json)[i];
            array.push(
                <Typography variant="body2" gutterBottom>
                    {terms_json[target]}
                </Typography>
            )
        }
        return array;
    }

    return (
        <div style={{ backgroundColor: "#E5E5E5", padding: 30, wordWrap: "break-word", marginTop: 10 }}>
            <Typography variant="h6" gutterBottom>
                <b>{t('terms.title').replace(/%s/g, campaignData['title_' + i18n.language])}</b>
            </Typography>
            {returnTerms()}
        </div>
    );
}

export default withTranslation()(Tnc);
