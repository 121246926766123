import React from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';

function Footer() {
    return (
        <Paper style={{
            position: "fixed",
            left: "0",
            bottom: "0",
            width: "100%",
            textAlign: "left",
            zIndex: 900
        }}>
            <Container maxWidth="lg">
                <Typography variant="caption" color="primary" style={{ padding: "0px 5px" }}>All right reserved by YOHO@2021</Typography>
            </Container>
        </Paper >
    );
}

export default Footer;