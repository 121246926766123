import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { withTranslation } from 'react-i18next';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import Avatar from '@material-ui/core/Avatar';
import {primary_color} from './Theme.js';

function NoEvent(props) {
    const { t, i18n } = props;
    const btn = [
        {
            src: require('../img/White_FB_2x-01.png'),
            href: 'https://www.facebook.com/yohohongkong'
        },
        {
            src: require('../img/White_ig_2x-01.png'),
            href: 'https://instagram.com/yohohongkong/'
        },
        {
            src: require('../img/White_youtube_2x-01.png'),
            href: 'https://https://www.youtube.com/user/yohohongkong'
        },
        {
            src: require('../img/White_twitter_2x-01.png'),
            href: 'https://twitter.com/yohohongkong'
        },
        {
            src: require('../img/White_weibo_2x-01.png'),
            href: 'https://weibo.cn/u/3264689962'
        },
    ]
    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            style={{ height: "80vh" }}
        >
            <Grid item>
                <LoyaltyIcon color="primary" style={{ fontSize: 200 }} />
            </Grid>
            <Grid item>
                <Typography variant="h4" gutterBottom>
                    {t('no_event.title')}
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant="subtitle1" gutterBottom>
                    {t('no_event.subtitle')}
                </Typography>
            </Grid>
            <Grid item>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    spacing={1}
                >
                    {btn.map(item => (
                        <Grid item>
                            <IconButton href={item.href} color="primary">
                                <Avatar style={{ backgroundColor: primary_color }}><img src={item.src} style={{ width: "100%" }} /></Avatar>
                            </IconButton>
                        </Grid>
                    ))}

                </Grid>
            </Grid>
            <Grid item style={{ marginTop: 50 }}>
                <Button size="large" color="primary" endIcon={<ArrowForwardIcon />} href="https://yohohongkong.com">{t('no_event.btn_text')}</Button>
            </Grid>
        </Grid>
    );
}

export default withTranslation()(NoEvent);
