import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import i18n from './i18n';
import { withTranslation } from 'react-i18next';
import {primary_color} from './Theme.js';
import Tnc from './Tnc';

function EventDescription(props) {
    const parse = require('html-react-parser');
    const { campaignData, next, t, i18n } = props;
    const btn_img = {
        0: {
            tc: require('../img/superpass_web_btn_3.png'),
            en: require('../img/superpass_web_v2_btn_3_EN.png')
        },
        1: {
            tc: require('../img/iphone12_fastpass-05.png'),
            en: require('../img/superpass_web_btn_1_EN.png')
        }
    }
    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            spacing={5}
        >
            <Grid item>
                <Typography variant="h6" gutterBottom>
                    {parse(t('intro.title').replace(/%s/g, '<b style="color:'+primary_color+'">'+campaignData["title_" + i18n.language]+'</b>'))}
                </Typography>
            </Grid>
            <Grid item style={{ width: "100%" }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                        <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                        >
                            <Grid item>
                                <Grid
                                    container
                                    direction="column"
                                    justify="center"
                                    alignItems="flex-start"
                                    spacing={2}
                                >
                                    {
                                        [{
                                            img: require('../img/iphone12_fastpass-02.png'),
                                            title: t('intro.subtitle_1'),
                                            content: t('intro.subtitle_1_des').replace(/%s/g, campaignData['title_' + i18n.language])
                                        },
                                        // {
                                        //     img: require('../img/iphone12_fastpass-03.png'),
                                        //     title: t('intro.subtitle_2'),
                                        //     content: t('intro.subtitle_2_des')
                                        // },
                                        {
                                            img: require('../img/iphone12_fastpass-04.png'),
                                            title: t('intro.subtitle_3'),
                                            content: t('intro.subtitle_3_des')
                                        }].map(e => (
                                            <Grid item>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={2}>
                                                        <Grid
                                                            container
                                                            direction="column"
                                                            justify="center"
                                                            alignItems="center"
                                                            style={{ height: "100%" }}
                                                        >
                                                            <Grid item>
                                                                <img src={e.img} style={{ width: "100%" }} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={10}>
                                                        <Grid
                                                            container
                                                            direction="column"
                                                            justify="center"
                                                            alignItems="flex-start"
                                                            style={{ height: "100%" }}
                                                        >
                                                            <Grid item>
                                                                <Typography variant="h6" color="primary" gutterBottom>
                                                                    {e.title}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography variant="body2" gutterBottom>
                                                                    {e.content}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                            style={{ height: "100%" }}
                        >
                            <Grid item>
                            {campaignData.pre_reg_available == 1 ?
                                <Button onClick={next} style={{backgroundColor:'transparent'}}>
                                    <img src={btn_img[1][i18n.language]} style={{ maxWidth: "80vw" }} />
                                </Button>
                                :<img src={btn_img[0][i18n.language]} style={{ maxWidth: "80vw" }} />}
                            </Grid>
                            <br />
                            <Grid item>
                                <Typography variant="body2" gutterBottom>
                                    {t('intro.non_member')} <a href="https://www.yohohongkong.com/user.php?act=register">{t('intro.non_member_btn')}</a>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    {campaignData.pre_reg_available == 1 ?
                    <Grid item xs={12}>
                        <Tnc campaignData={campaignData}/>
                    </Grid>:""}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default withTranslation()(EventDescription);
