import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import TextField from '@material-ui/core/TextField';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import EmailIcon from '@material-ui/icons/Email';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import EventErrorDialog from './EventErrorDialog';
import { withTranslation } from 'react-i18next';
import i18n from './i18n';
import Env from './Env';
import Tnc from './Tnc';

function EventForm(props) {
    const { campaignData, load, setLoad, next, t, i18n } = props;
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [mobileCodeOpen, setMobileCodeOpen] = useState(false);
    const [name, setName] = useState('');
    const [mobileCode, setMobileCode] = useState('+852');
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');
    const [check1, setCheck1] = useState(false);
    const [check2, setCheck2] = useState(false);
    const countryCodes = require('country-codes-list')
    const myCountryCodesObject = countryCodes.customList('countryCallingCode', '{countryNameLocal}')
    const btn_img = {
        tc:require('../img/iphone12_fastpass-07.png'),
        en:require('../img/superpass_web_btn_1_EN.png')
    }
    function renderCountryMenuItem() {
        let array = [];
        Object.keys(myCountryCodesObject).forEach((e, index) => {
            if (myCountryCodesObject[e] != "")
                array.push(
                    mobileCodeOpen ?
                        <MenuItem value={"+" + e}>
                            {"+" + e + " - " + myCountryCodesObject[e]}
                        </MenuItem>
                        :
                        <MenuItem value={"+" + e}>
                            {"+" + e}
                        </MenuItem>
                )
        })
        return array;
    }

    function ValidateEmail(mail) {
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
            return (true)
        }
        return (false)
    }

    function onSubmit() {
        if (mobile == "" || email == "") {
            displayError(t('error.fields_empty'))
        } else if (!ValidateEmail(email)) {
            displayError(t('error.un_vaild_email'))
        } else if (!check1) {
            displayError(t('error.accept_tnC'))
        } else {
            setLoad(true);
            fetchUserId();
        }
    }

    function displayError(msg) {
        setError(true);
        setErrorMsg(msg);
    }

    async function fetchUserId() {
        const response = await fetch(Env+"/api/user.php?user_name=" + mobileCode + "-" + mobile + "&email=" + email, {
            method: 'get',
            mode: 'cors',
            cache: 'no-cache'
        });
        switch (response.status) {
            case 200:
                let json = await response.json();
                if (json.user_id && json.user_id != "") {
                    postRequst(json.user_id)
                } else {
                    displayError(t('error.no_account'));
                    setLoad(false);
                }
                break;
            case 400:
                displayError(t('error.event_expired'));
                setLoad(false);
                break;
            default:
                displayError("unknown error occurred");
                setLoad(false);
                break;
        }
    }

    async function postRequst(user_id) {
        const response = await fetch(Env+"/api/campaignPreReg.php", {
            method: 'post',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
                'Accept': 'application/json',
            },
            body: JSON.stringify({
                user_id: user_id,
                campaign_id: campaignData.campaign_id
            })
        });
        switch (response.status) {
            case 200:
                let json = await response.json();
                if (json.accept == "true") {
                    next(email);
                } else {
                    displayError(t('error.registered'));
                }
                setLoad(false);
                break;
            case 400:
                displayError(t('error.event_expired'));
                setLoad(false);
                break;
            default:
                displayError("unknown error occurred");
                setLoad(false);
                break;
        }
    }

    function returnTerms() {

        let array = [];
        let terms_json = JSON.parse(campaignData['tnc_'+i18n.language]);
        for (let i = 0; i < Object.keys(terms_json).length; i++) {
            let target = Object.keys(terms_json)[i];
            array.push(
                <Typography variant="body2" gutterBottom>
                    {terms_json[target]}
                </Typography>
            )
        }
        return array;
    }

    return (
        <React.Fragment>
            <div>
                <Typography variant="h4" gutterBottom>
                    {t('form.title')}
                </Typography>
                <Typography variant="body2" gutterBottom>
                    {t('form.subtitle')}
                </Typography>
                {/* <Grid container style={{ marginTop: 10 }}>
                    <Grid item xs={1}>
                        <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                            style={{ height: "100%" }}
                        >
                            <Grid item>
                                <PermIdentityIcon />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={11}>
                        <TextField value={name} onChange={e => setName(e.target.value)} label="name" placeholder="e.g. Franz" variant="outlined" fullWidth />
                    </Grid>
                </Grid> */}
                <Grid container style={{ marginTop: 10 }}>
                    <Grid item xs={1}>
                        <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                            style={{ height: "100%" }}
                        >
                            <Grid item>
                                <PhoneAndroidIcon />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={3}>
                        <Select
                            label="Age"
                            variant="outlined"
                            defaultValue="+852"
                            onOpen={() => setMobileCodeOpen(true)}
                            onClose={() => setMobileCodeOpen(false)}
                            value={mobileCode} onChange={e => setMobileCode(e.target.value)}
                            fullWidth
                        >
                            {renderCountryMenuItem()}
                        </Select>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField value={mobile} onChange={e => setMobile(e.target.value)} label={t('form.mobile')} placeholder="e.g. 98765432" variant="outlined" fullWidth />
                    </Grid>
                </Grid>
                <Grid container style={{ marginTop: 10 }}>
                    <Grid item xs={1}>
                        <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                            style={{ height: "100%" }}
                        >
                            <Grid item>
                                <EmailIcon />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={11}>
                        <TextField value={email} onChange={e => setEmail(e.target.value)} label={t('form.email')} placeholder="e.g. whatever@yohohongkong.com" variant="outlined" fullWidth />
                    </Grid>
                </Grid>
                <Tnc campaignData={campaignData}/>
                <FormControlLabel
                    control={<Checkbox color="primary" checked={check1} onChange={e => setCheck1(e.target.checked)} />}
                    label={t('form.accept_terms')}
                />
                <br />
                {/* <FormControlLabel
                    control={<Checkbox color="primary" checked={check2} onChange={e => setCheck2(e.target.checked)} />}
                    label="bbbbbbbb"
                /> */}
                <br />
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                >
                    <Grid item>
                        <Button onClick={() => onSubmit()} style={{backgroundColor:'transparent'}}>
                            <img src={btn_img[i18n.language]} style={{maxWidth:"80vw"}}/>
                        </Button>
                    </Grid>
                </Grid>
            </div>
            <EventErrorDialog open={error} onClose={() => setError(false)} title={t('error.title')} content={errorMsg} />
        </React.Fragment>
    );
}

export default withTranslation()(EventForm);
