import React, { useState, useEffect } from 'react';
import CssBaseline from "@material-ui/core/CssBaseline";
import { Theme } from './Theme.js';
import AppMenu from './AppMenu';
import EventDescription from './EventDescription';
import EventForm from './EventForm';
import Container from '@material-ui/core/Container';
import EventRegSuccess from './EventRegSuccess';
import NoEvent from './NoEvent';
import Footer from './Footer.js';
import LoadingBackdrop from './LoadingBackdrop';
import Hidden from '@material-ui/core/Hidden';
import i18n from './i18n';
import Env from './Env';
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";

function App(props) {
  const { t, i18n } = props;
  const [load, setLoad] = useState(true);
  const [campaignData, setCampaignData] = useState(null);
  const [language, setLanguage] = useState('tc');
  const [userStep, setUserStep] = useState(0);
  const [email, setEmail] = useState('');
  const init_cover_img = {
    tc: {
      desktop: {
        0: "",
        2: require('../img/iphone12_fastpass_desktop_980x470_thanks.png')
      },
      mobile: {
        0: "",
        2: require('../img/iphone12_fastpass_mobile_374x267_2.png')
      }
    },
    en: {
      desktop: {
        0: "",
        2: require('../img/superpass_web_desktop_980x470_thanks_EN.png')
      },
      mobile: {
        0: "",
        2: require('../img/superpass_web_mobile_374x267_EN-19.png')
      }
    }
  }
  const [cover_img, setCoverImg] = useState(init_cover_img);

  useEffect(() => {
    fetchCampaign();
  }, []);

  async function fetchCampaign() {
    const response = await fetch(Env + "/api/campaign.php", {
      method: 'get',
      mode: 'cors',
      cache: 'no-cache'
    });
    switch (response.status) {
      case 200:
        let json = await response.json();
        setLoad(false);
        if (json[0]) {
          setCampaignData(json[0]);
          let temp_json = Object.assign({}, cover_img);
          temp_json.tc.desktop[0] = json[0].img_url_tc;
          temp_json.en.desktop[0] = json[0].img_url_en;
          temp_json.tc.mobile[0] = json[0].img_url_tc_mobile;
          temp_json.en.mobile[0] = json[0].img_url_en_mobile;
          setCoverImg(temp_json);
        }
        console.log(cover_img);
        break;
      default:
        setLoad(false);
        break;
    }
  }

  function renderContent() {
    switch (userStep) {
      case 0:
        return (
          <EventDescription campaignData={campaignData} next={() => { setUserStep(1) }} />
        );
      case 1:
        return <EventForm campaignData={campaignData} next={email => { setEmail(email); setUserStep(2); }} load={load} setLoad={setLoad} />;
      case 2:
        return <EventRegSuccess email={email} />
      default:
        return;
    }
  }

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t('meta.title')}</title>
        <meta name="description" content={campaignData ? t('intro.title').replace(/%s/g, campaignData['title_' + i18n.language]) : t('meta.title')} />
        <meta property="og:type" content="type" />
        <meta property="og:title" content={t('meta.title')} />
        <meta property="og:description" content={campaignData ? t('intro.title').replace(/%s/g, campaignData['title_' + i18n.language]) : t('meta.title')} />
        <meta property="og:image" content={cover_img[i18n.language]['mobile'][0]} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:site_name" content={t('meta.title')} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Theme>
        <CssBaseline />
        <LoadingBackdrop open={load} />
        <AppMenu campaignData={campaignData} setLanguage={setLanguage} userStep={userStep} />
        {!load || campaignData ?
          <React.Fragment>
            {campaignData ?
              <React.Fragment>
                {userStep == 0 || userStep == 2 ?
                  <React.Fragment>
                    <Hidden smDown>
                      <img src={cover_img[i18n.language]['desktop'][userStep]} style={{ backgroundColor: "#E5E5E5", maxHeight: 470, width: "100%", objectFit: "contain" }} />
                    </Hidden>
                    <Hidden mdUp>
                      <img src={cover_img[i18n.language]['mobile'][userStep]} style={{ backgroundColor: "#E5E5E5", width: "100%", objectFit: "contain" }} />
                    </Hidden>
                  </React.Fragment>
                  : ""}
                <Container maxWidth="md" style={{ margin: "50px auto" }}>
                  {renderContent()}
                  <Footer />
                </Container>
              </React.Fragment>
              : <Container maxWidth="md" style={{ marginTop: 20 }}><NoEvent /><Footer /></Container>
            }
          </React.Fragment>
          : ""}
      </Theme>
    </React.Fragment>
  );
}

export default withTranslation()(App);
